import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Loadable from "@loadable/component";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { MDContent } from "../components/Content";

// import react-apexcharts here
const LoadableChart = Loadable(() =>
  import("../../node_modules/react-apexcharts/src/react-apexcharts")
);

export const UnderstandingYourCreditPageTemplate = ({
  image,
  title,
  main,
  section2,
  foot,
  helmet,
}) => (
  <div className="content">
    {helmet || ""}
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `center`,
        minHeight: 450,
      }}
    >
      <h2 className="has-text-weight-bold is-size-1 has-text-white">{title}</h2>
    </div>

    <section>
      <div id="main" className="section">
        <div className="container">
          <h4 className="title is-3 has-text-centered">{main.title}</h4>
          <hr />
          <div className="columns">
            <div className="column is-one-third">
              <div className="has-text-weight-semibold">
                <MDContent content={main.left} />
              </div>
            </div>
            <div className="column is-two-thirds">
              <div className="has-text-weight-semibold">
                <MDContent content={main.right} />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-around",
                  minHeight: "10rem",
                }}
              >
                <PreviewCompatibleImage
                  imageInfo={{
                    image: main.equifax,
                    alt: "Equifax",
                    style: { width: 175 },
                  }}
                />
                <PreviewCompatibleImage
                  imageInfo={{
                    image: main.experian,
                    alt: "Experian",
                    style: { width: 175 },
                  }}
                />
                <PreviewCompatibleImage
                  imageInfo={{
                    image: main.transunion,
                    alt: "Transunion",
                    style: { width: 175 },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="section has-background-dark has-text-white">
        <div className="container has-text-centered has-text-white">
          <h3 className="title is-3 has-text-centered has-text-white">
            {section2.title}
          </h3>
          <h5 className="title is-5 has-text-centered has-text-white">
            {section2.subtitle}
          </h5>
          <p className="px-3">{section2.text}</p>
        </div>
        <div className="container my-4">
          <div className="columns has-text-dark">
            <div className="column is-half" style={{ minHeight: "30rem" }}>
              <div
                className="has-background-light"
                style={{ height: "100%", padding: "1rem" }}
              >
                <MDContent content={section2.left} />
              </div>
            </div>
            <div className="column is-half" style={{ minHeight: "30rem" }}>
              <div
                className="has-background-light has-text-centered"
                style={{ height: "100%", padding: "1rem" }}
              >
                <MDContent content={section2.right} />
                <div className="donut p-4">
                  {console.log(
                    Array.isArray(section2.rightGraph)
                      ? section2.rightGraph.map((item) => item.title)
                      : []
                  )}
                  <LoadableChart
                    type="donut"
                    series={
                      Array.isArray(section2.rightGraph)
                        ? section2.rightGraph.map((item) => item.value)
                        : []
                    }
                    options={{
                      labels: Array.isArray(section2.rightGraph)
                        ? section2.rightGraph.map((item) => item.title)
                        : [],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="section">
        <div className="container">
          <div>
            <div className="columns has-text-centered">
              <div className="column is-half">
                <h3 className="title is-3">{foot.left.title}</h3>
                <hr />
                <div>
                  <MDContent content={foot.left.text} />
                </div>
              </div>
              <div className="column is-half">
                <h3 className="title is-3">{foot.right.title}</h3>
                <hr />
                <div>
                  <MDContent content={foot.right.text} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

UnderstandingYourCreditPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  main: PropTypes.object,
  section2: PropTypes.object,
  foot: PropTypes.object,
};

const UnderstandingYourCreditPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <UnderstandingYourCreditPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        main={frontmatter.main}
        section2={frontmatter.section2}
        foot={frontmatter.foot}
        helmet={
          <Helmet titleTemplate={`Understanding Your Credit| Get Started`}>
            <title>{`Understanding Your Credit`}</title>
            <meta name="description" content={`Understanding Your Credit`} />
          </Helmet>
        }
      />
    </Layout>
  );
};

UnderstandingYourCreditPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default UnderstandingYourCreditPage;

export const UnderstandingYourCreditPageQuery = graphql`
  query UnderstandingYourCreditPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        main {
          title
          left
          right
          equifax {
            childImageSharp {
              fluid(maxWidth: 175, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          experian {
            childImageSharp {
              fluid(maxWidth: 175, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          transunion {
            childImageSharp {
              fluid(maxWidth: 175, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        section2 {
          title
          subtitle
          text
          left
          right
          rightGraph {
            title
            value
          }
        }
        foot {
          left {
            title
            text
          }
          right {
            title
            text
          }
        }
      }
    }
  }
`;
